<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo" href="/">
          <h2 class="brand-text text-primary ml-1">YBM Casting</h2>
        </b-link>

        <!-- form -->
        <small class="text-danger">{{ status }}</small>
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="info@ybmaluminium.com"
                  autofocus
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <!-- password 
              <div class="d-flex justify-content-between">
                <label for="password">Şifre</label>
                <b-link :to="{ name: '/profile' }">
                  <small>Şifremi Unuttum</small>
                </b-link>
              </div>-->
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="logIn()"
            >
              Giriş
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    this.$router.beforeEach(async (to, from, next) => {
      const isAuthenticated = localStorage.getItem("token");

      if (to.name === "login" && isAuthenticated) {
        next("/home-edit");
      } else if (to.name === "home-edit" && !isAuthenticated) {
        // Eğer yetkilendirilmemiş bir kullanıcı "/home-edit" rotasına erişmeye çalışıyorsa, login sayfasına yönlendir
        next("/login");
      } else {
        next();
      }
    });
  },
  methods: {
    async logIn() {
      const requestData = {
        email: this.userEmail,
        password: this.password,
      };
      try {
        const response = await axios.post("/api/Auth/Login", requestData );

        if (response.data.success) {
          const token = response.data.data;
          localStorage.setItem("token", token);
          axios.defaults.headers.common.Authorization = `Bearer ${token}`
          this.$store.dispatch('rabia/saveToken', token)

          this.$router.push("/home-edit");
          this.userEmail = "";
          this.password = "";
        } else {
          console.error("API yanıtında başarısızlık:", response.data.message);
          this.status = response.data.message; // Set the specific error message
        }
      } catch (error) {
        //  console.error("Login failed:", error);

        if (error.response && error.response.status === 401) {
          // 401 hatası: Kullanıcı adı veya şifre hatalı
          this.status = "Hatalı e-posta veya şifre. Lütfen tekrar deneyin.";
        } else if (error.response && error.response.status === 500) {
          // 500 hatası: Sunucu hatası
          this.status = "Sunucu hatası. Lütfen daha sonra tekrar deneyin.";
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // Handle specific API error message
          this.status = error.response.data.message;
        } else {
          // Diğer hatalar
          this.status = "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.";
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
